import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const BlackOwnedRacist = () => (
  <Layout>
    <SEO
      title="Is Black Owned Racist"
      description="Is black owned a terrible racist thing that needs to stop or is there some good in it? Let's look at some arguments for both sides on this tough subject"
    />
    <h1>Is 'Black Owned' Racist?</h1>
    <h2 style={{color:"Orchid"}}>Is black owned a terrible racist thing that needs to stop or is there some good in it. Let's look at some arguments for both sides on this tough subject.</h2>

    <p>You might be thinking that I am biased on this topic. Especially since I’m writing on a website called natural afro hair care. The truth is that I am biased and you probably are too. Right now I can’t think of a situation where a person doesn’t have some kind of bias in this area. With that being said, I’d like to be as fair as I can and I hope you leave here with a balanced view on the subject.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/CruxQjq.jpg"
        alt="wigs for black women header"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@mikepetrucci"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Mike Petrucci</a></p>
    </div>

    <p>I want to start by saying that we are all individuals. I think that at least hearing another person’s opinion and trying to understand it makes for a good society. You don’t have to be angry at a person who holds an opinion that seems like the opposite of yours. You can choose to move on and get on with your life.</p>
    <p>On the other hand, people who hold views that could lead to harmful actions aimed at certain groups of people can’t be left alone can they? Left to form groups, create a movement and eventually cause chaos. Multiple sides all with different ideas of what’s right.</p>
    <p>That is the essence of why people get heated about different views and I completely understand that. Something that seems like harmless words now, could turn into something far greater in the future if left unchecked.</p>
    <p>I think now is a good time to get to the point of the article and discuss whether “black owned” is racist or not.</p>

    <h2>See from the other side</h2>
    <p>Yes, choosing whether to buy from a company based on the colour of the owners is racist. One of the logical points I see is, “what if things were switched”. What if a white person went into a shop and decided not to purchase the items in hand because they realised the owners were black. Would that be racist? I think so. It’s straight forward, “I’m not buying from you because you are black”. If there’s an argument against that, I haven’t heard it.</p>
    <p>Now, picture a black person doing the same thing to a white person. Would that be racist? I think so too. There is a group of people that say black people can’t be racist, I’d like to go on record saying I don’t hold that view. Black people can be racist.</p>
    <p>The problem with switching the example like that is; things are not that black and white. The history of the world makes things complicated. Here’s an example;</p>
    <p>Let’s look at afro or black hair care products along with wigs, weaves and other accessories. For all the money that black people spend in the beauty industry on products catered to black people, black people don’t benefit financially nearly as much as other ethnicities.</p>
    <p>I don’t want to speak for all people of colour but when someone wants to buy from their own. It’s not so much; not wanting to buy from white people and more of; I want to buy from my own. There are a few positives to this way of thinking.</p>

    <h3 style={{color:"Orchid"}}>Community</h3>
    <p>The money spent gets to stay in the community. When there is money in the community, people can invest and raise the value of their area. Eventually making it a better place to live. There will be better education, more opportunities, higher employment etc.</p>

    <h3 style={{color:"Orchid"}}>Allows each ethnicity to make money from their own culture</h3>
    <p>We’ve all heard of terms like culture vulture and cultural appropriation. Actions that lead to benefiting from another group's culture whilst giving nothing back. Buying from your own means your people can benefit from the culture that they created. Other people are more than welcome to purchase too but the money comes back.</p>

    <h3 style={{color:"Orchid"}}>Understanding</h3>
    <p>There are nuances to cultures that are difficult to understand unless you are from the culture or have great empathy. Think about all the restaurants that serve foreign food in your country. How accurate are they to their claimed origin?</p>
    <p>Yes, there are some that are run by the people native to that place but there are a lot that aren’t. To the average person, it might not be so obvious that there is a difference. For example, I live in the UK. I had fish and chips in an American airport, it was not fish and chips.</p>
    <p>Something to note is that when people say they want to buy from their own, it doesn’t mean every single product. Again, there is a large group of black people that will only buy black owned hair products.</p>
    <p>So if there is so much controversy around the topic, why have big brands chosen to back the movement?</p>
    <p>Big brands have to be careful around sensitive topics because they have a reputation to maintain. In this age, news travels fast. One day your business could be set to be on for its best year and the next, you’ve run into some great controversy and dark times are around the corner.</p>
    <p>When there is an issue, sometimes it’s not enough to stay silent. It seems to be safer to speak up and show support. It seems that support goes to causes that will cause no great harm. Where the outcome of showing no support is worse than showing it.</p>
    <p>The type of people that are upset by these types of things are minimal. The type of people who are upset and will voice their opinion in public are even fewer and you have to ask; are they the type of people i want in my business or even want to worry about upsetting. If a person is angry enough about supporting black owned businesses to rage on twitter or start a protest then good riddance.</p>
    <p>Since we can’t keep everyone happy, sometimes it’s worth upsetting a few to make many happy.</p>

    <h2>Conclusion</h2>
    <p>Whether black owned is racist or not is up to you and how you interpret someone's actions. Yes, some people have malice in them and will support black ownership because they dislike other cultures. Then there’s a whole set of people that support black owned business because they want to give back and see a culture thrive.</p>


    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default BlackOwnedRacist
